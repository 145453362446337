<template>
  <section id="template-kits-filters">
    <b-row>
      <b-col cols="6" class="pl-0">
        <label>
          Kit
        </label>
        <b-form-input
          id="kit"
          type="text"
          v-model="filters.query"
          placeholder="Buscar por template de kit"
          debounce="1000"
        />
      </b-col>
      <b-col cols="4">
        <label>
          Produto incluso no kit
        </label>
        <Autocomplete
          v-model="filters.product"
          :options="productOptions"
          placeholder="Digite para pesquisar"
          @update="searchProduct($event)"
          debounce="1000" 
        />
      </b-col>
      <b-col cols="2" class="mt-1">
        <PeriodSelect
          label="Período"
          @select="handlePeriod"
          top="35px"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  name: 'kit-templates-filters',
  components: {
    Autocomplete: () => import('@/components/Autocomplete.vue'),
    PeriodSelect: () => import('@/components/General/Periods.vue')
  },
  data () {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      productOptions: [],
      filters: {
        query: null,
        product: null,

        start_date: null,
        end_date: null
      }
    }
  },

  methods: {
    handlePeriod(value) {
      this.filters.start_date = value.start
      this.filters.end_date = value.end
    },
    async searchProduct (query) {
      if (!query?.length) return;

      try {
        const res = await this.api.searchProducts(this.clinic.id, query) 
        this.productOptions = res.data.map(product => ({
          value: product?.id,
          barcode: product?.barcode,
          label: `${product?.barcode ? product?.barcode+' - ': ''}${product?.name}`,
        }))
      } catch (error) {
        this.$toast.error('Não foi possível encontrar o produto, contato o suporte se o erro persistir.')
      }
    },
  },
  watch: {
    filters: {
      handler: function (val) {
        this.$emit('update', val)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
#template-kits-filters {
  padding: 0px 10px;

  label {
    color: var(--dark-blue);
    font-weight: 700;
  }
}
</style>